import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import greenCheckmark from '../../../../images/icons/green-checkmark.svg';
import chevron from '../../../../images/mobile-chevron.svg';
import {
  AGREEMENT_STATUS_ACTION_REQUIRED,
  AGREEMENT_STATUS_SIGNED
} from '../../../../utils/constants';
import {
  SectionHeader,
  StatusIndicator
} from '../../Registration/AgreementForm';

const Title = styled.h3`
  &&& {
    font-weight: ${prop('theme.fontWeights.semiBold')};
    line-height: 20px;
  }
`;

const IconContainer = styled.div`
  margin-left: 16px;
`;

const propTypes = {
  agreement: PropTypes.object,
  index: PropTypes.number,
  isExpanded: PropTypes.arrayOf(PropTypes.bool),
  isTransitioning: PropTypes.arrayOf(PropTypes.bool),
  setIsExpanded: PropTypes.func,
  title: PropTypes.string
};

const defaultProps = {
  agreement: {},
  index: null,
  isExpanded: [],
  isTransitioning: [],
  setIsExpanded: () => {},
  title: ''
};

function AgreementHeader({
  agreement,
  index,
  isExpanded,
  isTransitioning,
  setIsExpanded,
  title
}) {
  const isSigned = agreement.status === AGREEMENT_STATUS_SIGNED;

  return (
    <SectionHeader
      $canBeExpanded
      $isExpanded={isExpanded[index] || isTransitioning[index]}
      onClick={() => {
        setIsExpanded((prev) => {
          const newIsExpanded = [...prev];
          newIsExpanded.splice(index, 1, !prev[index]);
          return newIsExpanded;
        });
      }}
    >
      <div>
        <Title>{title}</Title>
        {isSigned ? (
          <StatusIndicator className='signed' $status={AGREEMENT_STATUS_SIGNED}>
            <img src={greenCheckmark} />
            <span>{AGREEMENT_STATUS_SIGNED}</span>
          </StatusIndicator>
        ) : (
          <StatusIndicator $status={AGREEMENT_STATUS_ACTION_REQUIRED}>
            <span>{AGREEMENT_STATUS_ACTION_REQUIRED}</span>
          </StatusIndicator>
        )}
      </div>
      <IconContainer>
        <img
          src={chevron}
          className={isExpanded[index] ? 'expanded' : 'collapsed'}
        />
      </IconContainer>
    </SectionHeader>
  );
}

AgreementHeader.propTypes = propTypes;
AgreementHeader.defaultProps = defaultProps;

export default AgreementHeader;
