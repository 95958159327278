import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';

import AgreementsDisplay from './AgreementsDisplay';
import SpinnerContainer from '../../../SpinnerContainer';
import { fetchAgreements } from '../../../../state/actions/agreementActions';

const Container = styled.div`
  margin-bottom: 36px;

  p {
    font-size: 0.875rem;
    font-weight: ${prop('theme.fontWeights.light')};
    line-height: 1.5rem;
  }
`;

const Panel = styled.div`
  background-color: ${prop('theme.colors.white')};
  border: 1px solid ${prop('theme.colors.inputGrey')};
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  max-width: 856px;
  padding: 20px 42px;
`;

const Title = styled.h4`
  color: ${prop('theme.colors.black')};
  font-size: 1rem;
  font-weight: ${prop('theme.fontWeights.medium')};
  letter-spacing: 0.06em;
  line-height: 1rem;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const propTypes = {
  data: PropTypes.shape({
    items: PropTypes.array,
    primary: PropTypes.object,
    slice_type: PropTypes.string
  }),
  pageUid: PropTypes.string,
  template: PropTypes.string
};

const defaultProps = {
  data: {},
  pageUid: '',
  template: ''
};

function AgreementsList({ data, pageUid, template }) {
  const [agreementDocuments, setAgreementDocuments] = useState([]);
  const dispatch = useDispatch();
  const {
    memberInfo: { agreementTemplates },
    apiErrors
  } = useSelector((state) => state);

  if (!data) {
    return null;
  }

  const title = get(data, 'primary.title');
  const description = get(data, 'primary.description');
  const agreementsQueryResult = useStaticQuery(graphql`
    query CompletedAgreementQuery {
      allPrismicSubscriberAgreement {
        edges {
          node {
            uid
            data {
              title
              section {
                css_classname
                section_name
                section_body {
                  text
                  richText
                }
              }
              attachment_container {
                attachment_link {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    dispatch(fetchAgreements());
  }, []);

  useEffect(() => {
    if (agreementTemplates) {
      setAgreementDocuments(
        agreementsQueryResult.allPrismicSubscriberAgreement.edges
          .map((edge) => edge.node)
          .filter(
            (node) =>
              agreementTemplates.findIndex(
                (template) => template.docUid === node.uid
              ) > -1
          )
          .map((node) => {
            const index = agreementTemplates.findIndex(
              (template) => template.docUid === node.uid
            );
            return {
              ...node,
              status: agreementTemplates[index].agreementStatus,
              id: agreementTemplates[index].agreementId,
              signature: agreementTemplates[index].signature
            };
          })
      );
    }
  }, [agreementTemplates]);

  const noErrorsPresent = !apiErrors || Object.keys(apiErrors).length === 0;

  return (
    <Container>
      {title.richText && <Title>{title.text}</Title>}
      {description && <PrismicRichText field={description.richText} />}
      {agreementDocuments.length === 0 && (
        <SpinnerContainer height='300'>
          {noErrorsPresent && (
            <Spinner style={{ width: '4rem', height: '4rem' }} />
          )}
        </SpinnerContainer>
      )}
      {agreementDocuments.length >= 1 && (
        <AgreementsDisplay agreements={agreementDocuments} />
      )}
    </Container>
  );
}

AgreementsList.propTypes = propTypes;
AgreementsList.defaultProps = defaultProps;

export default AgreementsList;
