import React, { Fragment, useState } from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { Collapse } from 'reactstrap';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from 'utils/linkResolver';
import AgreementHeader from './AgreementHeader';
import { Section } from '../../Registration/AgreementForm';

const AgreementContainer = styled.div`
  background-color: ${prop('theme.colors.white')};
  border: 1px solid ${prop('theme.colors.inputGrey')};
  border-radius: 16px;
  max-width: 856px;
  padding: 16px 40px;
  margin: 24px 0;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    padding: 16px 20px;
  }
`;

const AttachmentLink = styled.a`
  color: ${prop('theme.colors.black')};
  font-weight: ${prop('theme.fontWeights.regular')};

  &:hover {
    color: ${prop('theme.colors.redPrimary')};
    font-weight: ${prop('theme.fontWeights.medium')};
    text-decoration: none;
  }
`;

const propTypes = {
  agreements: PropTypes.array
};

const defaultProps = {
  agreements: []
};

function AgreementsDisplay({ agreements }) {
  const [isExpanded, setIsExpanded] = useState(
    new Array(agreements.length).fill(false)
  );
  const [isTransitioning, setIsTransitioning] = useState(
    new Array(agreements.length).fill(false)
  );

  const startTransition = (index) => {
    setIsTransitioning((prev) => {
      const newIsTransitioning = [...prev];
      newIsTransitioning.splice(index, 1, true);
      return newIsTransitioning;
    });
  };

  const endTransition = (index) => {
    setIsTransitioning((prev) => {
      const newIsTransitioning = [...prev];
      newIsTransitioning.splice(index, 1, false);
      return newIsTransitioning;
    });
  };

  return (
    <>
      {agreements.map((agreement, index) => {
        const {
          title,
          agreement_body,
          section,
          attachment_container
        } = agreement.data;
        const attachmentLink = get(
          attachment_container,
          '[0].attachment_link.url'
        );

        return (
          <Fragment key={title}>
            <AgreementContainer>
              <AgreementHeader
                agreement={agreement}
                attachmentLink={attachmentLink}
                index={index}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                isTransitioning={isTransitioning}
                title={title}
              />
              <Collapse
                isOpen={isExpanded[index]}
                onEntering={() => startTransition(index)}
                onEntered={() => endTransition(index)}
                onExiting={() => startTransition(index)}
                onExited={() => endTransition(index)}
              >
                <div>
                  {section.slice(0, 1).map((sectionContent, sectionIndex) => (
                    <Section
                      className={sectionContent.css_classname || ''}
                      key={`${title}-${sectionIndex}`}
                    >
                      <PrismicRichText
                        field={sectionContent.section_body.richText}
                        linkResolver={linkResolver}
                      />
                    </Section>
                  ))}
                  {attachmentLink && (
                    <AttachmentLink href={attachmentLink} target='_blank'>
                      {`${title} Agreement`}
                    </AttachmentLink>
                  )}
                </div>
              </Collapse>
            </AgreementContainer>
          </Fragment>
        );
      })}
    </>
  );
}

AgreementsDisplay.propTypes = propTypes;
AgreementsDisplay.defaultProps = defaultProps;

export default AgreementsDisplay;
